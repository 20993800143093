
.dashboard--tabs_container {
    margin: 10px 0;
}

.dashboard--tab {
    padding: 12px 24px  !important;
    border-radius: 8px;
    margin: 20px;
}

.dashboard--tab:first-of-type {
    margin-left: 0;
}

.dashboard--tab.active-tab {
    background-color: #EDF9F2;
    font-weight: 600;
    color: #00A86B;
}
