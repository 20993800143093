.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border: 2px solid #F4F4F4;
  box-shadow: 0 8px 20px rgba(247, 248, 249, 0.8);
  border-radius: 40px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    color: #090A3A;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 20px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}
