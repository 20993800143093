.new-design-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & h4 {
        flex: 1;
        margin-left: 20px;
        font-weight: 600;
        font-size: 32px;
        line-height: 24px;

    }
}

.new-design-card-cta {
    align-self: center;
    margin-bottom: 0;
}

.new-design-image-preview {
    border: 6px solid rgba(234, 234, 234, 1);
    border-radius: 8px;
    width: 320px;
    height: 175px;
    object-fit: contain;
}

.image-layout_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.image-layout_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 366px;
    height: 247px;
    border-radius: 8px;
    border: 2px solid rgba(244, 244, 244, 1);
    padding: 20px;
}
