.organization-default-dashboard-modal {
    max-width: 601px;
    width: 100%;
    max-height: 408px;
    height: 100%;
    z-index: 1071;

    & .modal__close-btn {
        top: -5%;
        width: 24px;
        height: 24px;
    }

    & .welcome-user {
        margin: 20px auto;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
    }
}

.organization-default-dashboard-modal > .modal-content {

    border-radius: 40px;
    padding: 40px;
}

//.modal-content-organization {
//    max-width: 414px;
//    width: 100%;
//    height: 328px;
//    display: flex;
//    flex-direction: column;
//}


.functionality-section {
    margin: 10px auto;
}

.functionality-text {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.options-container {
    max-width: 277px;
    min-height: 234px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.option-card {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 12px 24px;
    margin: 10px auto;
    border-radius: 8px;
    border: 1px solid #f4f4f4;
    transition: all ease-in-out 0.3s;
}

.option-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #f4f4f4;
    margin-right: 15px;
    transition: all ease-in-out 0.1s;
}

.option-card:hover, .option-card.selected {
    & .option-icon {
        border: #2CBD6BFF 8px solid;
    }
    & .option-text {
        color: #2CBD6BFF;
        font-weight: 600;
    }
    background-color: #EDF9F2FF;
    border: #2CBD6BFF 1px solid;
}


.option-text {
    font-size: 20px;
    font-weight: 400;
    transition: all ease-in-out 0.3s;
}

.submit-button {
    width: 100%;
    margin-top: 15px;
}
