.pricing-card {
  text-align: center;

  &.pricing-card--primary {
    border-top: 5px solid $color-blue;
  }

  &.pricing-card--info {
    border-top: 5px solid $color-accent;
  }

  &.pricing-card--warning {
    border-top: 5px solid $color-yellow;
  }

  &.pricing-card--danger {
    border-top: 5px solid $color-red;
  }

  hr {
    margin: 0;

    @include themify($themes) {
      border-top-color: themed('colorFieldsBorder');
    }
  }
}

.pricing-card__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  width: 300px;
  margin: 20px 5px;
  position: relative;
  .pricing-card--emoji {
    font-size: 36px;
  }
}

.pricing-card__header {
  text-align: center;
}

.pricing-card-details__table {
  height: 0;
  transition: 0.3s;
  td {
    padding: 0;
    font-size: 0;
    height: 0;
    transition: 0.3s;
  }

  .pricing-card__check, .pricing-card__x {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    width: 0;
    transition: 0.3s;

    img {
      transition: 0.3s;
      height: 0px;
    }
  }
  .pricing-card__img {
    transition: 0.3s;
    height: 0;
  }
}

.pricing-card-details__table.up {
  height: 100%;
  td {
    font-size: inherit;
    height: initial;
    margin: 10px;
  }
  .pricing-card__check, .pricing-card__x {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;

    img {
      height: 10px;
    }
  }
  .pricing-card__img {
    height: 70px;
    margin-bottom: 20px;
  }
}

.pricing-card__check {
  background-color: $color-background-alt-green;
}

.pricing-card__x {
  background-color: #F7F8F9;
}

.pricing-card__img {
  height: 70px;
  margin-bottom: 20px;
}

.pricing-card__plan {
  margin-bottom: 20px;
  font-weight: 300;
}

.pricing-card__price {
  font-weight: 700;
  font-size: 56px;
  line-height: 48px;

  span {
    color: #A0ABBB;
    font-weight: 400;
    font-size: 14px;
  }
}

.pricing-card__feature, .pricing-card__non_feature {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
}

.pricing-card__feature {
  color: $color-main-text;
}

.pricing-card__free-trial-bubble {
  position: absolute;
  color: #fff;
  top: -15px;
  right: 40px;
  background: $color-main-text;
  padding: 8px 12px 8px 12px;
  border-radius: 100px;
  font-size: 16px;
}

.pricing-card__non_feature {
  color: #A0ABBB;
}

.pricing-card__feature--inactive {
  color: #bbbbbb;
}

.pricing-card__button_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pricing-card__button {
  margin-top: 25px;
  margin-bottom: 0;
  max-width: 160px;
  width: 100%;
}
