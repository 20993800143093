.current-billing_container {
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}

.current-billing-detail {
  border: 2px solid #F4F4F4;
  border-radius: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr) ;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  flex: 0 0 30%;
}


.emoji-container {
  font-size: 60px;
  grid-area: 1 / 1 / 3 / 2;
}
.billing-details-container { grid-area: 1 / 2 / 3 / 3; }


.current-billing--title {
  font-family: 'DM Mono';
  font-weight: 400;
  font-size: 14px;
}

.current-billing--info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}