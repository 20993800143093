@charset "UTF-8";
/* Base styling for the select container */
.select-container {
  position: relative;
  width: max-content;
  height: 40px;
  font-family: 'Arial', sans-serif; }

/* Styling for the select dropdown */
.select-container select {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-right: 25px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  appearance: none;
  /* Remove default appearance for different browsers */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f2f2f2;
  font-size: 16px;
  transition: background-color 0.3s;
  outline: none;
  /* Remove default focus styling */ }

/* Styling for the select dropdown on hover */
.select-container select:hover {
  background-color: #e0e0e0; }

/* Styling for the select dropdown arrow */
.select-container::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Makes sure the dropdown arrow doesn't interfere with the select functionality */
  font-size: 12px; }

/* Styling for the select dropdown options */
.select-container select option {
  padding: 10px;
  font-size: 16px; }

.add-user-icon {
  background: #EDF9F2;
  border-radius: 30px;
  color: #2CBD6B;
  cursor: pointer;
  padding: 5px; }

.floating-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  position: absolute;
  padding: 20px;
  width: 100%;
  z-index: 100; }
  .floating-card p {
    font-size: 16px;
    margin-bottom: 5px; }
  .floating-card ul > li {
    font-size: 14px;
    text-align: left; }

.floating-card.show-info {
  display: unset; }

.floating-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px; }
