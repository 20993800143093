
.social-media-card-container {
    position: relative;
    width: min-content;
    overflow: visible;
}


.social-media-cards-style {
    background: #fff;
    border: 1px solid rgba(223, 222, 219, 1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 435px;
    height: min-content;
    font-family: "Roboto", serif;
}

.social-media-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    padding: 10px;
}

.social-media-card-body.x {
    display: grid;
    grid-template-columns: 50px 1fr;
    padding: 20px;
}


.social-media-card-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.social-media-card-header.x {
    color: rgba(160, 171, 187, 1);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    justify-content: space-around;
    width: 80%;


    & p:first-of-type {
        color: #0f2137;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
    }
}

.card-header-title.facebook {
    color: rgba(57, 89, 150, 1);
    font-size: 12.71px;
    font-weight: 700;
    line-height: 17.24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
}

.sponsored-facebook {
    color: rgba(96, 103, 112, 1);
    font-size: 10.89px;
    font-weight: 400;
    line-height: 14.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: unset;
}

.facebook-circle {
    background-color: rgba(96, 103, 112, 1);
    border-radius: 50%;
    width: 3px;
    height: 3px;
    margin: 2px;
}


.card-facebook-comment {
    color: rgba(57, 89, 150, 1);
    font-size: 11.98px;
    font-weight: 400;
    line-height: 16.25px;
    letter-spacing: 0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.social-media-card-comment {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0f2137;

    & p:first-of-type {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
    }
}

.card-facebook-comment p {
    margin: 0;
    padding: 0;
}

.card-facebook-comment > p:first-of-type {
    color: rgba(29, 33, 41, 1);
}

.social-media-card-image {
    border-radius: 0 0 16px 16px;
}

.social-media-card-footer {
    font-size: 14px;
    & div {
        display: flex;
        align-items: center;
    }

    & div span {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 1420px) {
    width: 335px;
}

@media screen and (max-width: 1024px) {
    width: 300px;

    .social-media-card-icon-container.facebook {
        right: 20%;
        height: 62px;
        width: 62px;
    }

    .social-media-card-icon-container.x {
        height: 64px;
        width: 64px;
    }

    .social-media-card-icon-container.linkedin {
        top: -5%;
        right: 25%;
        height: 60px;
        width: 60px;
    }

    .social-media-card-body.x {
        grid-template-columns: 10% 80%;
        padding: 5px;
    }

    .social-media-card-header.x {
        font-size: 10px;
        width: 100%;

        & p:first-of-type {
            font-size: 14px;
        }
    }

    .social-media-card-footer {
        font-size: 10px;
    }
}

@media screen and (max-width: 768px) {
    width: 250px;

    .social-media-card-icon-container.x {
        height: 50px;
        width: 50px;
    }

    .social-media-card-icon-container.linkedin {
        height: 50px;
        width: 50px;
    }

    .social-media-card-footer {
        font-size: 8px;
    }

}

.linkedin-link-preview_image {
    width: 100%;
}

.linkedin-link-preview_description {
    width: 100%;
}


