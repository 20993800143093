@import '../../../scss/settings/variable';

.navigation {
  background: #fff;
  border-bottom: 1px solid #f8f8f8;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  @media screen and (max-width: 800px) {
    display: none;
  }
  transition: 1s all;
}

.navigation.navigation__top {
  position: fixed;
  top: -10px;
}

.navigation-placeholder {
  height: 0;
}

.navigation-placeholder.scroll {
  height: 100px;
}

.navigation--logo {
  display: flex;
  border: none !important;
  align-items: center;
  color: $color-main-text;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  height: 100px;
  line-height: 24px;
  &:hover {
    color: unset;
  }
}

.navigation--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1600px;
}

.navigation--item_container {
  display: flex;
  justify-content: space-between;
}

li {
  font-size: 16px;
  font-weight: 500;
  list-style: none;
}

.navigation-item > a, .navigation-logout, a.active {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: $color-main-text;
  padding: 40.5px 0;
  margin: 20px;
  transition: 0.2s;
  @media screen and (max-width: 1010px){
    font-size: 12px;
    margin: 10px;
  }
}


.navigation-item > a:hover, .navigation-logout:hover, a.active, a.tabActive {
  border-bottom: 2px solid $color-green;
  color: $color-green;
}

.navigation--initials__dropdown {
  position: relative;
}

.dropdown--container {
  position: relative;
}

.navigation--initials-btn {
  background: $color-background-alt-green;
  border-radius: 30px;
  color: $color-green !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 10px;
}

.dropdown--content {
  display: none;
  background: #fff;
  box-shadow: 0 0 10px 0 #d0d0d0;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  right: 20px;

  p {
    margin-left: 10px;
  }

  li {
    margin: 25px auto;
  }

  span {
    border-bottom: 2px solid transparent;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    transition: 0.2s all;
  }

  span:hover {
    border-bottom: 2px solid $color-green;
    color: $color-green;
    cursor: pointer;
    p {
        color: $color-green;
    }
  }

  ul {
    padding: 0;
    text-align: center;
  }

}

.dropdown--content.show {
  display: unset;
}



//// Mobile Navigation Styles Below

