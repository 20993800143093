
.debug--tool_widget_options {
    height: 0;
    overflow: hidden;
    transition: 0.3s ease;

    & .form__form-group-input-wrap {
        height: 0;
        overflow: hidden;
        transition: 0.3s ease;
    }

    & .form__form-group-field {
        height: 0;
        overflow: hidden;
        transition: .3s;
    }
}

.debug--tool_widget_options.show {
    height: 350px;
    overflow: visible;

    & .form__form-group-input-wrap {
        height: 65px;
    }

    & .form__form-group-field {
        height: 20px;

    }
}

#go-to-top {
    scroll-margin-top: 60px;
}


.debug--tool_widget_results_container {
    height: 0;
    overflow: hidden;
    transition: 0.3s ease;
}

.debug--tool_widget_results_container.show {
    height: max-content;
    overflow: visible;
}
