.react_component_preview-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    grid-gap: 25px;
    margin: 4.5rem auto;
    max-width: 1280px;

    & h3 {
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 29.26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
}

.react_component_preview-section {
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(244, 244, 244, 1);
    max-width: 600px;
    min-height: 600px;
    padding: 10px;
    overflow: hidden;
    width: 100%;
}
.react_component_preview-section_inner-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding-left: 25px;
}

.react_component_preview-preview-tabs {
    display: flex;
    margin-bottom: 25px;
}

.react_component_preview-section:last-of-type {
    grid-area: 2 / 1 / 3 / 3;
    max-width: 100%;
    height: min-content;

    & button {
        max-width: 231px;
        width: 100%;
    }
}

.react_component_preview-section:nth-of-type(2) {
    justify-self: flex-end;
}

.react_component_preview-container.show {
    max-width: 1284px;
    margin: auto;
}

.react_component_preview-cards-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 5px;
    width: 100%;


    & h2 {
        font-size: 20px;
    }

    & > div > div, & > div {
        margin: 10px auto;
        width: 100%;
    }

    & img {
        width: 100%;
    }
}

.react_component_preview-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
}

.react_component_preview-tab_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 25px;
}

.react_component_preview-tab {
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    background: #f5f5f5;
    color: #2CBD6B;
}

.react_component_preview-tab.active {
    background: #2CBD6B;
    color: #fff;
}

.react_component_preview-tab-card {
    display: none;
    justify-self: flex-end;
}

.react_component_preview-tab-card.active {
    display: block;
}

@media (max-width: 1440px) {
    .react_component_preview-container {

    }
}


@media (max-width: 1280px) {
    .react_component_preview-cards-container {
        flex-direction: column-reverse;
        align-items: center;
    }
    .react_component_preview-card.small {
        width: max-content;
    }
}



.meta-tag-generator-form {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 25px;
    height: 425px;
    width: 100%;

    & label {
        color: rgba(9, 10, 58, 1);
        font-family: Nunito Sans, sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: unset;
    }

    & textarea, & input:last-of-type {
        color: rgba(147, 142, 158, 1);
    }

    & input, & textarea, & .input-button {
        width: 100%;
        padding: 12px 16px;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .input-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & input {
        transition: all 0.3s ease;
    }

    & input:focus, & textarea:focus {
        border-color: #2CBD6B;
        background-color: #fff;
        outline: none;
    }

    & input::placeholder, & textarea::placeholder {
        color: rgba(147, 142, 158, 1);
        font-style: italic;
    }
    & p {
        color: #78716c;
        margin-top: 0;
        font-size: 12px;
    }

    & .input-button {
        cursor: pointer;
    }

}

@media screen and (max-width: 930px) {
    .meta-tag-generator-form input, .meta-tag-generator-form textarea {
        width: 100%;
        max-width: 300px;
    }
}

@media screen and (max-width: 375px) {
    .meta-tag-generator-form input, .meta-tag-generator-form textarea {
        width: 100%;
        max-width: 200px;
    }
}

.link-preview-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    & h3 {
        font-weight: 700;
    }
}

.url_preview-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    height: 50px;
    padding: 10px;
    width: 100%;
    margin: 10px auto;

}

.url_preview-form-input {
    border-radius: 8px;
    border: 2px solid #f4f4f4;
    padding: 12px 16px 12px 16px;
}

.url_preview-form label {
    color: #2CBD6B;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    padding: 20px;
}

.url_preview-form input, .url_preview-form input:focus-visible {
    flex: 1;
    outline: none;
    font-size: 1.4rem;
    font-family: 'Nunito Sans', sans-serif;
    color: #4f555d;
}

.url_preview-form button {
    margin-left: 10px;
    width: max-content;
}

.link-preview-no-tag-alert {
    background-color: #fdecea;
    color: #611a15;
    padding: 10px 15px;
    border: 1px solid #f5c2c0;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    margin: 25px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 600px;
}

.link-preview-no-tag-alert p {
    margin: 0;
    flex-grow: 1;
}

.dismiss-alert {
    font-size: 18px;
    color: #611a15;
    cursor: pointer;
    padding: 0 8px;
    line-height: 1;
}

.dismiss-alert:hover {
    color: #a33c32;
}

