.filler-bar {
    @media (max-width: 930px) {
        min-width: 15%;
    }
    @media (max-width: 700px) {
        min-width: 20%;
    }
    @media (max-width: 580px) {
        min-width: 25%;
    }
    @media (max-width: 580px) {
        min-width: 30%;
    }


}
