.your-api-key_key-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 2px solid #F4F4F4;
    border-radius: 8px;
    padding: 12px 8px;
    position: relative;
}

.your-api-key_key-display_copy {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.your-api-key_key-display_copy-icon {
    position: relative;
}

.your-api-key_key-display_copy-icon:hover::before,
.your-api-key_key-display_copy-icon:hover::after {
    content: "";
    position: absolute;
    border: 2px solid #f30000; /* Adjust border color as needed */
    border-radius: 4px;
}

.your-api-key_key-display_copy-icon:hover::before {
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
}

.your-api-key_key-display_copy-icon:hover::after {
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.copy-text {
    position: absolute;
    top: -40px; /* Adjust position as needed */
    left: 50%;
    transform: translateX(-50%);
    display: none;
    color: #eff1f5;
    padding: 5px;
    background: #fff;
    box-shadow: 0 10px 30px 0 #1920331A;
    border-radius: 4px;
    z-index: 2000;
}

.copy-text::after {
    content: '';
    position: absolute;
    top: 100%; /* Position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent; /* Match background color of tooltip */
}

.your-api-key_key-display_copy:hover .copy-text {
    display: block;
}
