
.billing--heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billing--down_arrow {
  background: $color-background-alt-green;
  border-radius: 100px;
  color: $color-green-hover;
  cursor: pointer;
  transition: 0.3s;
}

.billing--down_arrow.up {
  transform: rotate(180deg);
}

.billing--tier--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
