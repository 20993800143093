.pagination {

}

.pagination__link {
  color: $color-accent;

  &:hover {
    color: darken($color-green, 10%);
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.active {
    background: $color-green;
    color: white;
  }

  &.pagination__link--arrow {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;

    background: $color-green;
    svg {
      fill: white;
    }
    &:hover {
      background: $color-green-hover;

      svg {
        fill: white;
      }
    }
  }
}

.pagination__item {
  cursor: pointer;

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &.disabled {

    .pagination__link svg {

      @include themify($themes) {
        fill: themed('colorIcon');
      }
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.pagination-info {
  color: $color-additional;
  font-size: 12px;
  margin: auto 0 auto 10px;
}
