@import '../../../scss/settings/variable';

.menu {
  background: transparent;
  border: 0;
  float: left;
  margin: 27px 20px 0 35px;

  span {
    background: $color-main-text;
    cursor: pointer;
    clear: left;
    float: left;
    height: 5px;
    margin-bottom: 2px;
    transition: all .2s;
    width: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.mobile-navigation {
  background: #fff;
  border-bottom: 1px solid #f8f8f8;
  @media screen and (min-width: 800px) {
    display: none;
  }
}

.mobile-navigation--logo {
  display: flex;
  border: none !important;
  align-items: center;
  color: $color-main-text;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  height: 100px;
  line-height: 24px;
  &:hover {
    color: unset;
  }
}

.mobile-navigation--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1600px;
}

.mobile-navigation--item_container {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  width: 100%;
  left: 0;
  top: 100px;
  position: absolute;
  overflow: clip;
  transition: 0.3s ease-in-out;
}

.mobile-navigation--item_container.open {
  height: 250px;
}

.mobile-navigation-item {
  margin: 20px auto;
  width: 100%;
}

li {
  font-size: 16px;
  font-weight: 500;
  list-style: none;
}

a, .mobile-navigation-logout {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: $color-main-text;
  transition: 0.2s;
}

a:hover, .navigation-logout:hover {
  border-bottom: 2px solid $color-green;
  color: $color-green;
}